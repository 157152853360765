import React, { useEffect } from 'react'
import { Text, View } from 'react-native'
import tailwind from 'tailwind-rn'
import ChangePassword from '../components/ChangePassword'
import { useAuthState } from '../contexts/AuthContext'

import { BASE_URL, fetchWithToken } from '../utils/common'
import HomeItems from '../components/HomeItems'
import HomeHeader from '../components/HomeHeader'
import useSWR from 'swr'
import { ActivityIndicator } from 'react-native-paper'

export default function HomeScreen({ navigation, userToken }) {
  const authState = useAuthState()

  if (authState.isFirstLogin) return <ChangePassword />

  console.log('authState.codigoColaborador', authState.codigoColaborador)

  const url = `${BASE_URL}/api/colaborador?codigo=${authState.codigoColaborador}`
  const { data, error, isValidating } = useSWR([url, userToken], fetchWithToken)

  return (
    <View style={tailwind('bg-green-800 w-full h-full')}>
      <HomeHeader navigation={navigation} />

      {isValidating && (
        <View
          style={tailwind(
            'flex flex-row items-center justify-center mx-16 rounded-lg p-4 bg-gray-600'
          )}
        >
          <ActivityIndicator color="white" />
          <Text
            style={tailwind('text-white font-bold text-center ml-2 text-lg')}
          >
            Carregando...
          </Text>
        </View>
      )}

      {!isValidating && data && data.colaborador && (
        <HomeItems
          colaborador={data.colaborador}
          colaboradorVinculo={data.colaboradorVinculo}
          navigation={navigation}
        />
      )}

      {error && (
        <Text
          style={tailwind(
            'flex justify-center items-center text-gray-300 text-lg'
          )}
        >
          Sem dados para exibir
        </Text>
      )}
    </View>
  )
}
