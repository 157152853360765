import React, { useState } from 'react'
import { FlatList, View, Text, ActivityIndicator, Platform } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import tailwind, { getColor } from 'tailwind-rn'
import { AntDesign } from '@expo/vector-icons'
import useFetch from '../utils/useFetch'
import { BASE_URL } from '../utils/common'
import { useAuthState } from '../contexts/AuthContext'
import * as FileSystem from 'expo-file-system'
import * as Sharing from 'expo-sharing'

export default function DeclaracaoRendimentoScreen({ navigation, userToken }) {
  const authState = useAuthState()

  const [loadingPdf, setLoadingPdf] = useState(false)
  const [selectedId, setSelectedId] = useState()

  const { response, error, loading } = useFetch(
    `${BASE_URL}/api/declaracoesRendimentos?codigoColaborador=${authState.codigoColaborador}`,
    {
      method: 'GET',
      headers: { Authorization: userToken },
    }
  )

  const generatePdfAndShare = async (declaracaoId) => {
    try {
      setLoadingPdf(true)
      setSelectedId(declaracaoId)
      const resData = await fetch(
        `${BASE_URL}/api/declaracoesRendimentos/gerarPdf?id=${declaracaoId}`,
        {
          method: 'GET',
          headers: { Authorization: userToken },
        }
      )
      const { pdfData } = await resData.json()
      if (Platform.OS === 'web') {
        var binary = atob(pdfData.replace(/\s/g, ''))
        var len = binary.length
        var buffer = new ArrayBuffer(len)
        var view = new Uint8Array(buffer)
        for (var i = 0; i < len; i++) {
          view[i] = binary.charCodeAt(i)
        }
        var blob = new Blob([view], { type: 'application/pdf' })
        var url = URL.createObjectURL(blob)
        window.open(url)
      } else {
        const pdfPath = FileSystem.cacheDirectory + 'declaracao-rendimento.pdf'
        await FileSystem.writeAsStringAsync(pdfPath, pdfData, {
          encoding: FileSystem.EncodingType.Base64,
        })
        await Sharing.shareAsync(pdfPath)
      }
      console.log('generated pdf!')
      setLoadingPdf(false)
      setSelectedId()
    } catch (e) {
      console.log(e)
      alert('Ocorreu um erro ao tentar gerar o PDF:', e.message)
      setLoadingPdf(false)
    }
  }

  const renderItem = ({ item }) =>
    loadingPdf && selectedId === item.id ? (
      <View
        style={tailwind(
          'rounded-lg bg-green-800 p-6 mx-5 mt-3 flex-row justify-between items-center'
        )}
      >
        <ActivityIndicator color="white" />
        <Text style={tailwind('text-white font-bold text-center ml-2')}>
          Carregando...
        </Text>
      </View>
    ) : (
      <TouchableOpacity
        style={tailwind(
          `rounded-lg bg-green-800 p-6 mx-5 mt-3 flex-row justify-between items-center`
        )}
        onPress={() => generatePdfAndShare(item.id)}
      >
        <View style={tailwind('flex-row')}>
          <AntDesign name="download" size={20} color={getColor('white')} />
          <Text
            style={tailwind('text-white uppercase font-bold text-base ml-2')}
          >
            {item.ano}
          </Text>
        </View>
        <Text style={tailwind('text-white font-bold text-base')}>
          R$ {item.totalRendimentos}
        </Text>
      </TouchableOpacity>
    )

  return (
    <View style={tailwind('flex-col bg-gray-200 h-full w-full')}>
      {loading && (
        <View style={tailwind('flex justify-center items-center mt-4')}>
          <ActivityIndicator size={50} color={getColor('blue-800')} />
        </View>
      )}
      {!loading &&
        !error &&
        response &&
        response.data &&
        response.data.length >= 1 && (
          <FlatList
            data={response.data}
            renderItem={renderItem}
            keyExtractor={(item) => JSON.stringify(item)}
            style={{ width: '100%' }}
          />
        )}
      {!loading && !error && response.data.length === 0 && (
        <View style={tailwind('bg-gray-400 py-4 mx-5 mt-4 rounded-lg')}>
          <Text style={tailwind('text-center text-sm text-gray-600')}>
            Ainda não há declarações disponíveis para o ano selecionado
          </Text>
        </View>
      )}
    </View>
  )
}
